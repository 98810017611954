
import { defineComponent, reactive } from 'vue';
import DataServer from '@/server/DataServer';
import JSON_DATA from '../assets/json/data.json';

export default defineComponent({
  name: 'HospitalTop',
  props: {
    title: String,
    chartIndex: Number,
    reloadTime: Number,
  },
  data() {
    return {
      config: reactive({
        rowNum: 7,
        data: [],
        unit: '例',
      }),
    };
  },
  components: {},
  mounted: function () {
    this.getHospitalRank(Number(this.chartIndex));
    setInterval(async () => {
      this.getHospitalRank(Number(this.chartIndex));
    }, this.reloadTime * 60 * 1000);
  },
  methods: {
    getHospitalRank(index: number) {
      this.config.data = JSON_DATA.HospitalTop.map((item: any) => {
        return {
          name: item.name,
          value: item.value * 2,
        };
      });
      // DataServer.getChartData(index).then((res: any) => {
      //   if (res.status === 200) {
      //     this.config.data = res.data.result[0].data;
      //   }
      // });
    },
  },
});
