
import { defineComponent } from 'vue';
import { reactive } from 'vue';
import DataServer from '@/server/DataServer';
export default defineComponent({
  name: 'TotalInfo',
  props: ['title', 'chartIndex', 'startDay', 'reloadTime'],
  data() {
    return {
      totalNum: reactive({
        number: [0],
        content: '{nt}例',
        style: {
          fontSize: 32,
          fill: '#19d1e1',
        },
      }),
      todayNum: reactive({
        number: [0],
        content: '{nt}例',
        style: {
          fontSize: 32,
          fill: '#19d1e1',
        },
      }),
      totalHospital: reactive({
        number: [0],
        content: '{nt}家',
        style: {
          fontSize: 32,
          fill: '#19d1e1',
        },
      }),
      startedDays: reactive({
        number: [0],
        content: '{nt}天',
        style: {
          fontSize: 32,
          fill: '#19d1e1',
        },
      }),
    };
  },
  mounted: function () {
    this.getTotalCaseNum(Number(this.chartIndex[0]));
    this.getStartedDays(String(this.startDay));
    this.getTotalHospitalNum(Number(this.chartIndex[1]));
    this.getTodayCaseNum(Number(this.chartIndex[2]));

    setInterval(async () => {
      this.getTotalCaseNum(Number(this.chartIndex[0]));
      this.getStartedDays(String(this.startDay));
      this.getTotalHospitalNum(Number(this.chartIndex[1]));
      this.getTodayCaseNum(Number(this.chartIndex[2]));
    }, this.reloadTime * 60 * 1000);
  },
  methods: {
    getTotalCaseNum(index: number) {
      DataServer.getChartData(index).then((res: any) => {
        if (res.status === 200) {
          this.totalNum.number[0] = res.data.result[0].data[0].count;
        }
      });
      this.totalNum.number[0] = 10267;
    },
    getStartedDays(startDay: string) {
      const startDayDate = new Date(startDay + ' 00:00:00');
      const now = new Date();
      const days = Math.ceil((now.getTime() - startDayDate.getTime()) / (1000 * 60 * 60 * 24));
      this.startedDays.number[0] = days;
    },
    getTotalHospitalNum(index: number) {
      // DataServer.getChartData(index).then((res: any) => {
      //   if (res.status === 200) {
      //     this.totalHospital.number[0] = res.data.result[0].data[0].count;
      //   }
      // });
      this.totalHospital.number[0] = 104;
    },
    getTodayCaseNum(index: number) {
      // DataServer.getChartData(index).then((res: any) => {
      //   if (res.status === 200) {
      //     this.todayNum.number[0] = res.data.result[0].data[0].count;
      //   }
      // });
      this.todayNum.number[0] = 198;
      // let today = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
      // DataServer.getChartDataTody(index, today).then((res: any) => {
      //   if (res.status === 200) {
      //     this.todayNum.number[0] = res.data.result[0].data[0].count;
      //   }
      // });
    },
  },
});
