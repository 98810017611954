
import { defineComponent } from 'vue';
import { Area } from '@antv/g2plot';
import DataServer from '@/server/DataServer';
//导入assets/json/data.json
import JSON_DATA from '../assets/json/data.json';

export default defineComponent({
  name: 'RealTime',
  props: ['title', 'chartIndex', 'reloadTime'],
  data() {
    return {
      // title: 'CDSS辅助诊断系统数据大屏',
    };
  },
  components: {},
  mounted: async function () {
    this.setChart();
  },
  methods: {
    async getData(index: number) {
      return new Promise<void>((resolve, reject) => {
        DataServer.getChartData(index)
          .then((res: any) => {
            if (res.status === 200) {
              resolve(res.data.result[0].data);
            }
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },
    async setChart() {
      // let data: any = await this.getData(this.chartIndex);
      // data = this.formatData(data);
      //使用导入的JSON_DATA
      let data: any = JSON_DATA;

      console.log(data);
      //mock data, hour: 0-23 value: 0-100, 随机数据
      data = [];
      for (let i = 0; i < 24; i++) {
        let value = 0;
        if ((i > 8 && i < 12) || (i > 14 && i < 16)) {
          value = Math.floor(Math.random() * 100);
        }
        data.push({
          hour: String(i),
          value: value,
        });
      }

      let area = new Area('realtime', {
        data,
        xField: 'hour',
        yField: 'value',
        xAxis: {
          range: [0, 1],
          tickCount: 12,
        },
        color: '#18c7d8',
        smooth: true,
        yAxis: {
          grid: {
            line: {
              style: {
                lineWidth: 0,
              },
            },
          },
        },
        meta: {
          hour: {
            alias: '时间',
          },
          value: {
            alias: '病例数',
          },
        },
        areaStyle: () => {
          return {
            fill: 'l(270) 0:#18c7d8 0.5:#18c7d8 1:#18c7d8',
          };
        },
      });
      area.render();
      setInterval(async () => {
        let data: any = await this.getData(this.chartIndex);
        data = this.formatData(data);
        area.changeData(data);
      }, this.reloadTime * 60 * 1000);
    },
    formatData(data: any) {
      const array: { hour: string; value: number }[] = [];
      for (let i = 0; i < 24; i++) {
        array.push({
          hour: String(i),
          value: 0,
        });
      }
      data.forEach((element: any) => {
        array[Number(element.hours)].value = element.value;
      });
      return array;
    },
  },
});
