
import { defineComponent, reactive } from 'vue';
import DataServer from '@/server/DataServer';
import JSON_DATA from '../assets/json/data.json';

export default defineComponent({
  name: 'HospitalTop',
  props: ['title', 'chartIndex', 'reloadTime'],
  data() {
    return {
      config: reactive({
        rowNum: 8,
        header: ['医生姓名', '接诊数量', '完成诊断数量'],
        data: [[]],
        index: true,
        columnWidth: [50],
        align: ['center'],
        headerBGC: '#3f96a500',
        oddRowBGC: '#06132e',
        evenRowBGC: '#1370fb30',
      }),
    };
  },
  components: {},
  mounted: function () {
    this.getData(this.chartIndex);
    setInterval(async () => {
      this.getData(this.chartIndex);
    }, this.reloadTime * 60 * 1000);
  },
  methods: {
    getData(index: number) {
      this.config.data = this.formatData(JSON_DATA.DoctorTop);
      // DataServer.getChartData(index).then((res: any) => {
      //   if (res.status === 200) {
      //     this.config.data = this.formatData(res.data.result[0].data);
      //   }
      // });
    },
    formatData(data: any) {
      let array: any[] = [];
      data.forEach((item: any) => {
        //item.done为item.value范围内随机数
        item.done = Math.floor(Math.random() * item.value);
        array.push([item.name, Number(item.value) * 3, item.done]);
      });
      return array;
    },
  },
});
